// .mdc-button {
//     height: 2.81rem !important;
//     border-radius: 0 !important;
//     font-size: 1rem !important;
//     line-height: 25px !important;
//     padding: .5rem 4rem !important;

//     &.mat-primary {
//         color: white !important;
//     }

//     &.mat-accent {
//         color: #666666 !important;
//     }
// }


// .plus-btn {
//     background-color: transparent;
//     border: none;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     gap: 1.5rem;

//     img {
//         width: 28px;
//         height: 28px;
//     }

//     &:hover {
//         opacity: .8;
//     }
// }

// .dot-menu-btn {
//     background-color: transparent;
//     border: none;
//     cursor: pointer;
//     display: flex;
//     align-items: center;

//     img {
//         width: 24px;
//         height: 24px;
//     }

//     &:hover {
//         opacity: .8;
//     }
// }

// .edit-btn {
//     background-color: transparent;
//     border: none;
//     cursor: pointer;
//     display: flex;
//     align-items: center;

//     img {
//         width: 18px;
//         height: auto;
//         margin-top: -4px;
//     }

//     &:hover {
//         opacity: .8;
//     }
// }


// .primary-btn {
//     padding: 10px;
//     width: 150px;
//     border-radius: 20px;
//     color: white;
//     border: 1px solid var(--primary-color);
//     background-color: var(--primary-color);
//     text-transform: uppercase;
//     cursor: pointer;
//     transition: all .2s ease;

//     &:hover {
//         color: var(--primary-color);
//         background-color: white;
//     }
// }

.primary-btn {
    &,
    &:link,
    &:visited {
        text-transform: uppercase;
        text-decoration: none;
        padding: 1.5rem 4rem;
        display: inline-block;
        border-radius: 10rem;
        transition: all .2s;
        position: relative;
        // font-size: $default-font-size;

        //Change for the <button> element
        border: none;
        cursor: pointer;
    }
    
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(black,.2);

        &::after {
            transform: scaleX(1.4) scaleY(1.6);
            opacity: 0;
        }
    }
    
    &:active,
    &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba(black,.2);
    }
    
    &--white {
        background-color: white !important;
        color: var(--primary-color) !important;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: white !important;
        }
    }

    &--secondary {
        background-color: var(--secondary-color) !important;
        color: white !important;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: var(--secondary-color) !important;
        }
    }

    &--primary {
        background-color: var(--primary-color);
        color: white;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: var(--primary-color);
        }
    }
    
    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }
    
    &--animated {
        animation: moveInBottom .5s ease-out .75s;
        animation-fill-mode: backwards;
    }
}

.medium-btn {
    &,
    &:link,
    &:visited {        
        text-decoration: none;
        padding: 1rem 2rem;        
        border-radius: 10rem;
        transition: all .2s;
        position: relative;            
        border: none;
        cursor: pointer;
    }   
    
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(black,.2);

        &::after {
            transform: scaleX(1.4) scaleY(1.6);
            opacity: 0;
        }
    }
    
    &:active,
    &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba(black,.2);
    }
    
    &--white {
        background-color: white !important;
        color: var(--primary-color) !important;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: white !important;
        }
    }

    &--secondary {
        background-color: var(--secondary-color) !important;
        color: white !important;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: var(--secondary-color) !important;
        }
    }

    &--primary {
        background-color: var(--primary-color);
        color: white;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: var(--primary-color);
        }
    }
    
    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }
    
    &--animated {
        animation: moveInBottom .5s ease-out .75s;
        animation-fill-mode: backwards;
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.small-btn {
    &,
    &:link,
    &:visited {        
        text-decoration: none;
        padding: 0.3rem 1rem;        
        border-radius: 10rem;
        transition: all .2s;
        position: relative;            
        border: none;
        cursor: pointer;
    }   
    
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(black,.2);

        &::after {
            transform: scaleX(1.4) scaleY(1.6);
            opacity: 0;
        }
    }
    
    &:active,
    &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: 0 .5rem 1rem rgba(black,.2);
    }
    
    &--white {
        background-color: white !important;
        color: var(--primary-color) !important;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: white !important;
        }
    }

    &--secondary {
        background-color: var(--secondary-color) !important;
        color: white !important;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: var(--secondary-color) !important;
        }
    }

    &--primary {
        background-color: var(--primary-color);
        color: white;
        box-shadow: 0 .5rem 1rem rgba(black,.2);

        &::after {
            background-color: var(--primary-color);
        }
    }
    
    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }
    
    &--animated {
        animation: moveInBottom .5s ease-out .75s;
        animation-fill-mode: backwards;
    }
}