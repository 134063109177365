.status {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 4px;

    &.primary {
        background-color: #37A2D9;
    }

    &.success {
        background-color: #34B104;
    }

    &.default {
        background-color: #999999;
    }
}