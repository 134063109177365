.module-title {
    display: flex;
    align-items: center;
    gap: 1.18rem;

    h2 {
        color: #000000;
        font-size: 1.75rem;
        line-height: 40px;
        font-weight: 600;
    }
}