:root {
    --primary-color: #9D0759;
    --secondary-color: #12676A;
    --secondary-color-light: #1EABB0;
    --tertiary-color: #fffbeb;
    --white-color: #ffffff;
    --box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);   
}       

$box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
