.form-group {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;

    label {
        display: flex;
        align-items: center;
        gap: .2rem;
        color: #333333;
        font-weight: 600;
        font-size: .875rem;

        .label-remark {
            color: #333333;
            font-weight: 400;
        }

        .star {
            color: #FF0000;
        }
    }

    .remark {
        position: absolute;
        right: -18rem;
        top: 50%;
        transform: translateY(-50%);
        width: 225px;

        p {
            color: #666666;
            font-size: .875rem;
            line-height: 18px;
            font-weight: 400;
        }
    }

    .remark-icon {
        width: 1.1rem;
        height: 1.1rem;
        display: none;

        &:hover {
            opacity: .8;
        }
    }

    &.dark {
        .mat-mdc-text-field-wrapper {
            background-color: #F4F4F4 !important;
            border-radius: 0 !important;
        }
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }

    .mat-mdc-form-field {
        width: 100% !important;
    }

    .mat-mdc-text-field-wrapper {
        background-color: white !important;
        border-radius: 0 !important;
    }

    .mdc-line-ripple::before {
        border-color: #909090 !important;
    }

    .mdc-line-ripple--active::after {
        border-color: var(--primary-color) !important;
    }

    .mat-mdc-icon-button {
        color: var(--primary-color) !important;
    }

    &.file {
        position: relative;

        .file-input-label {
            width: 6.25rem;
            height: 6.25rem;
            border: 1px solid #CCCCCC;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }

        input {
            width: 0;
            height: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &.checkbox {
        .checkbox-list {
            display: flex;
            gap: 1.375rem;
            list-style: none;

            .checkbox-wrapper {
                display: flex;
                align-items: center;
                gap: 0.625rem;

                label {
                    color: #333333;
                    font-size: 0.875rem;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
        }
    }

    &.file.dark {
        .file-input-label {
            background-color: #F5F5F5;
        }
    }
}

.mat-calendar {
    .mdc-button__label {
        white-space: nowrap !important;
    }
}

.mdc-checkbox__checkmark {
    color: white !important;
}



// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    .form-group {
        .remark {
            display: none;
        }

        .remark-icon {
            display: block !important;
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (max-width: 1400px) {}