@font-face {
    font-family: "Comfortaa-VariableFont_wght";
    src: url("../fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf");
}

$primary-font: "Comfortaa-VariableFont_wght";

@font-face {
    font-family: "SocialIcons";
    src: url("../fonts/SocialIcons/SocialIcons.eot?xmnl9c");
    src:
        url("../fonts/SocialIcons/SocialIcons.eot?xmnl9c#iefix") format("embedded-opentype"),
        url("../fonts/SocialIcons/SocialIcons.ttf?xmnl9c") format("truetype"),
        url("../fonts/SocialIcons/SocialIcons.woff?xmnl9c") format("woff"),
        url("../fonts/SocialIcons/SocialIcons.svg?xmnl9c#SocialIcons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.social-icons {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "SocialIcons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.soc-9Gag:before {
    content: "\e984";
}
.soc-AdobePortfolio:before {
    content: "\e943";
}
.soc-AirBnB:before {
    content: "\e002";
}
.soc-AliExpress:before {
    content: "\e91d";
}
.soc-Alibaba:before {
    content: "\e922";
}
.soc-Alliance:before {
    content: "\e003";
}
.soc-Amazon:before {
    content: "\e004";
}
.soc-Amplement:before {
    content: "\e005";
}
.soc-Android:before {
    content: "\e006";
}
.soc-AngelList:before {
    content: "\e007";
}
.soc-AngiesList:before {
    content: "\e916";
}
.soc-AnimationFramework:before {
    content: "\e9f2";
}
.soc-AnimePlanet:before {
    content: "\e9b7";
}
.soc-AnthroArt:before {
    content: "\e9c1";
}
.soc-AppNet:before {
    content: "\e009";
}
.soc-AppStore:before {
    content: "\e91f";
}
.soc-Apple:before {
    content: "\e008";
}
.soc-ArchiveOfOurOwn:before {
    content: "\e9c2";
}
.soc-Archiveorg:before {
    content: "\e997";
}
.soc-ArtFight:before {
    content: "\e988";
}
.soc-ArtHaven:before {
    content: "\e9c3";
}
.soc-ArtStation:before {
    content: "\e959";
}
.soc-Artfol:before {
    content: "\e98a";
}
.soc-Artistree:before {
    content: "\e99e";
}
.soc-Ask:before {
    content: "\e930";
}
.soc-Augment:before {
    content: "\e908";
}
.soc-Avvo:before {
    content: "\e978";
}
.soc-Baidu:before {
    content: "\e00a";
}
.soc-Bale:before {
    content: "\e97e";
}
.soc-BandCamp:before {
    content: "\e00b";
}
.soc-BattleNet:before {
    content: "\e00c";
}
.soc-BeBee:before {
    content: "\e00e";
}
.soc-Beacons:before {
    content: "\e9d0";
}
.soc-BeatPort:before {
    content: "\e955";
}
.soc-Bebo:before {
    content: "\e00f";
}
.soc-Behance:before {
    content: "\e010";
}
.soc-Bing:before {
    content: "\e92a";
}
.soc-BitBucket:before {
    content: "\e909";
}
.soc-Blackberry:before {
    content: "\e951";
}
.soc-Blingee:before {
    content: "\e9d6";
}
.soc-Blizzard:before {
    content: "\e011";
}
.soc-Blogger:before {
    content: "\e012";
}
.soc-Bloglovin:before {
    content: "\e904";
}
.soc-BlueSky:before {
    content: "\e98d";
}
.soc-Bonanza:before {
    content: "\e92c";
}
.soc-Bookbub:before {
    content: "\e949";
}
.soc-Booking:before {
    content: "\e936";
}
.soc-Buffer:before {
    content: "\e013";
}
.soc-Bukkit:before {
    content: "\e9d7";
}
.soc-BuyMeACoffee:before {
    content: "\e9c4";
}
.soc-Buzzly:before {
    content: "\e994";
}
.soc-Calendly:before {
    content: "\e93d";
}
.soc-Cara:before {
    content: "\e9a1";
}
.soc-Carrd:before {
    content: "\e981";
}
.soc-CdBaby:before {
    content: "\e968";
}
.soc-CharacterHub:before {
    content: "\e9aa";
}
.soc-ChickenSmoothie:before {
    content: "\e9d4";
}
.soc-Chrome:before {
    content: "\e014";
}
.soc-CodePen:before {
    content: "\e917";
}
.soc-Codeberg:before {
    content: "\e9d8";
}
.soc-CoderWall:before {
    content: "\e015";
}
.soc-Codered:before {
    content: "\e940";
}
.soc-Cohost:before {
    content: "\e9af";
}
.soc-Collectorz:before {
    content: "\e95c";
}
.soc-ComicFury:before {
    content: "\e99d";
}
.soc-CraigsList:before {
    content: "\e923";
}
.soc-CrunchBase:before {
    content: "\e93b";
}
.soc-Curiouscat:before {
    content: "\e989";
}
.soc-Curse:before {
    content: "\e016";
}
.soc-Dailymotion:before {
    content: "\e017";
}
.soc-Debian:before {
    content: "\e95f";
}
.soc-Deezer:before {
    content: "\e018";
}
.soc-Delicious:before {
    content: "\e019";
}
.soc-Deviantart:before {
    content: "\e01a";
}
.soc-Diablo:before {
    content: "\e01b";
}
.soc-Digg:before {
    content: "\e01c";
}
.soc-DigitalOcean:before {
    content: "\e9a0";
}
.soc-Discord:before {
    content: "\e985";
}
.soc-Displate:before {
    content: "\e9c5";
}
.soc-Disqus:before {
    content: "\e01e";
}
.soc-Dlive:before {
    content: "\e975";
}
.soc-Doodle:before {
    content: "\e92b";
}
.soc-Douban:before {
    content: "\e01f";
}
.soc-Draugiem:before {
    content: "\e020";
}
.soc-DreamWidth:before {
    content: "\e9d9";
}
.soc-Dribbble:before {
    content: "\e021";
}
.soc-Dropbox:before {
    content: "\e9da";
}
.soc-Drupal:before {
    content: "\e022";
}
.soc-Ebay:before {
    content: "\e023";
}
.soc-Eitaa:before {
    content: "\e97c";
}
.soc-ElementaryOS:before {
    content: "\e969";
}
.soc-Ello:before {
    content: "\e024";
}
.soc-Endomodo:before {
    content: "\e025";
}
.soc-Envato:before {
    content: "\e026";
}
.soc-Escargot:before {
    content: "\e9b8";
}
.soc-Etsy:before {
    content: "\e027";
}
.soc-Facebook:before {
    content: "\e028";
}
.soc-Fandom:before {
    content: "\e9c6";
}
.soc-Fediverse:before {
    content: "\e9db";
}
.soc-FeedBurner:before {
    content: "\e029";
}
.soc-Filegarden:before {
    content: "\e9dc";
}
.soc-FilmFreeway:before {
    content: "\e960";
}
.soc-FilmWeb:before {
    content: "\e02a";
}
.soc-Firefox:before {
    content: "\e02b";
}
.soc-Fiverr:before {
    content: "\e91b";
}
.soc-Flash:before {
    content: "\e919";
}
.soc-Flattr:before {
    content: "\e02c";
}
.soc-Flickr:before {
    content: "\e02d";
}
.soc-FlightRising:before {
    content: "\e9a9";
}
.soc-FlipBoard:before {
    content: "\e973";
}
.soc-FlowCode:before {
    content: "\e99f";
}
.soc-Forgejo:before {
    content: "\e9dd";
}
.soc-Formulr:before {
    content: "\e02e";
}
.soc-Forrst:before {
    content: "\e02f";
}
.soc-FourSquare:before {
    content: "\e030";
}
.soc-Freelancer:before {
    content: "\e928";
}
.soc-FriendFeed:before {
    content: "\e031";
}
.soc-Fundable:before {
    content: "\e935";
}
.soc-FurAffinity:before {
    content: "\e987";
}
.soc-FurryNetwork:before {
    content: "\e9d1";
}
.soc-Furvilla:before {
    content: "\e998";
}
.soc-Fyuse:before {
    content: "\e90a";
}
.soc-GOG:before {
    content: "\e9b9";
}
.soc-GaiaOnline:before {
    content: "\e9ba";
}
.soc-GameFor:before {
    content: "\e94e";
}
.soc-GameJolt:before {
    content: "\e901";
}
.soc-GameWisp:before {
    content: "\e905";
}
.soc-Genius:before {
    content: "\e9cf";
}
.soc-Ghost:before {
    content: "\e933";
}
.soc-Git:before {
    content: "\e9de";
}
.soc-GitHub:before {
    content: "\e032";
}
.soc-Gitea:before {
    content: "\e9df";
}
.soc-Gitlab:before {
    content: "\e945";
}
.soc-Gitter:before {
    content: "\e96c";
}
.soc-Glaze:before {
    content: "\e98f";
}
.soc-Gnome:before {
    content: "\e961";
}
.soc-GoToMeeting:before {
    content: "\e91c";
}
.soc-Goatlings:before {
    content: "\e9e0";
}
.soc-Gogs:before {
    content: "\e9e1";
}
.soc-GoodReads:before {
    content: "\e033";
}
.soc-Google:before {
    content: "\e034";
}
.soc-GoogleCalendar:before {
    content: "\e926";
}
.soc-GoogleDrive:before {
    content: "\e9e2";
}
.soc-GoogleGroups:before {
    content: "\e036";
}
.soc-GoogleHangouts:before {
    content: "\e974";
}
.soc-GoogleMaps:before {
    content: "\e937";
}
.soc-GooglePhotos:before {
    content: "\e037";
}
.soc-GooglePlayMusic:before {
    content: "\e95e";
}
.soc-GooglePlus:before {
    content: "\e038";
}
.soc-GoogleScholar:before {
    content: "\e035";
}
.soc-GrooveShark:before {
    content: "\e039";
}
.soc-GrundosCafe:before {
    content: "\e9c7";
}
.soc-Guilded:before {
    content: "\e9bb";
}
.soc-Gumroad:before {
    content: "\e9c8";
}
.soc-Guru:before {
    content: "\e91e";
}
.soc-Gust:before {
    content: "\e92f";
}
.soc-HackerNews:before {
    content: "\e946";
}
.soc-HackerOne:before {
    content: "\e956";
}
.soc-HackerRank:before {
    content: "\e03a";
}
.soc-HearthStone:before {
    content: "\e03b";
}
.soc-Hellocoton:before {
    content: "\e03c";
}
.soc-Heroes:before {
    content: "\e03d";
}
.soc-HomeAdvisor:before {
    content: "\e915";
}
.soc-Homefy:before {
    content: "\e93c";
}
.soc-Homes:before {
    content: "\e920";
}
.soc-Horde:before {
    content: "\e03f";
}
.soc-Houzz:before {
    content: "\e040";
}
.soc-Hypixel:before {
    content: "\e9e3";
}
.soc-IMDb:before {
    content: "\e043";
}
.soc-Icq:before {
    content: "\e041";
}
.soc-Identica:before {
    content: "\e042";
}
.soc-Imgur:before {
    content: "\e9e4";
}
.soc-IndieDB:before {
    content: "\e94c";
}
.soc-Inkblot:before {
    content: "\e995";
}
.soc-Inkitt:before {
    content: "\e9e5";
}
.soc-Instagram:before {
    content: "\e044";
}
.soc-Instructables:before {
    content: "\e944";
}
.soc-Internet:before {
    content: "\e957";
}
.soc-Invision:before {
    content: "\e95a";
}
.soc-Issuu:before {
    content: "\e045";
}
.soc-Istock:before {
    content: "\e046";
}
.soc-Itaku:before {
    content: "\e9a8";
}
.soc-Itchio:before {
    content: "\e962";
}
.soc-Itunes:before {
    content: "\e047";
}
.soc-Jamendo:before {
    content: "\e963";
}
.soc-KeyBase:before {
    content: "\e048";
}
.soc-Kitsu:before {
    content: "\e9ab";
}
.soc-Ko-Fi:before {
    content: "\e983";
}
.soc-Kobo:before {
    content: "\e948";
}
.soc-Koo:before {
    content: "\e9e6";
}
.soc-Lanyrd:before {
    content: "\e049";
}
.soc-LastFM:before {
    content: "\e04a";
}
.soc-LeprdSpace:before {
    content: "\e990";
}
.soc-LetterBoxd:before {
    content: "\e96d";
}
.soc-Line:before {
    content: "\e04b";
}
.soc-Link:before {
    content: "\e9d2";
}
.soc-LinkedIn:before {
    content: "\e04c";
}
.soc-Linktree:before {
    content: "\e982";
}
.soc-LiveJournal:before {
    content: "\e04d";
}
.soc-LiveMaster:before {
    content: "\e93e";
}
.soc-LogMeIn:before {
    content: "\e91a";
}
.soc-Loomly:before {
    content: "\e932";
}
.soc-Lyft:before {
    content: "\e04e";
}
.soc-Mail:before {
    content: "\e050";
}
.soc-Mailru:before {
    content: "\e94a";
}
.soc-MakeShip:before {
    content: "\e9bc";
}
.soc-Mastodon:before {
    content: "\e913";
}
.soc-Matrix:before {
    content: "\e9b0";
}
.soc-Medium:before {
    content: "\e051";
}
.soc-Meetup:before {
    content: "\e052";
}
.soc-Messenger:before {
    content: "\e906";
}
.soc-Metapop:before {
    content: "\e970";
}
.soc-Microsoft:before {
    content: "\e90e";
}
.soc-Minecraft:before {
    content: "\e9b4";
}
.soc-Mix:before {
    content: "\e964";
}
.soc-Mixer:before {
    content: "\e00d";
}
.soc-MobCrush:before {
    content: "\e90d";
}
.soc-Moddb:before {
    content: "\e94b";
}
.soc-ModelMayhem:before {
    content: "\e054";
}
.soc-ModerNeopets:before {
    content: "\e999";
}
.soc-Modrinth:before {
    content: "\e9e7";
}
.soc-Mumble:before {
    content: "\e055";
}
.soc-MyAnimeList:before {
    content: "\e950";
}
.soc-MySpace:before {
    content: "\e056";
}
.soc-NaNoWriMo:before {
    content: "\e9e8";
}
.soc-NameMC:before {
    content: "\e9ad";
}
.soc-Napster:before {
    content: "\e954";
}
.soc-Natgeo:before {
    content: "\e912";
}
.soc-Naver:before {
    content: "\e971";
}
.soc-Nekoweb:before {
    content: "\e9b1";
}
.soc-Neocities:before {
    content: "\e992";
}
.soc-Neopets:before {
    content: "\e99a";
}
.soc-Newgrounds:before {
    content: "\e97f";
}
.soc-NewsVine:before {
    content: "\e057";
}
.soc-Nextdoor:before {
    content: "\e942";
}
.soc-NexusMods:before {
    content: "\e9d3";
}
.soc-Niconico:before {
    content: "\e939";
}
.soc-Nintendo:before {
    content: "\e058";
}
.soc-Npm:before {
    content: "\e059";
}
.soc-Odnoklassniki:before {
    content: "\e05a";
}
.soc-Opera:before {
    content: "\e05c";
}
.soc-Origin:before {
    content: "\e941";
}
.soc-Outlook:before {
    content: "\e05d";
}
.soc-Overwatch:before {
    content: "\e05e";
}
.soc-Pandora:before {
    content: "\e907";
}
.soc-PaperDemon:before {
    content: "\e9a6";
}
.soc-PaperMC:before {
    content: "\e9e9";
}
.soc-Patreon:before {
    content: "\e99b";
}
.soc-Paypal:before {
    content: "\e99c";
}
.soc-Periscope:before {
    content: "\e061";
}
.soc-Persona:before {
    content: "\e062";
}
.soc-PillowFort:before {
    content: "\e980";
}
.soc-Pinterest:before {
    content: "\e063";
}
.soc-Pixilart:before {
    content: "\e9d5";
}
.soc-Pixiv:before {
    content: "\e94f";
}
.soc-Play:before {
    content: "\e064";
}
.soc-PlayStation:before {
    content: "\e066";
}
.soc-Player:before {
    content: "\e065";
}
.soc-Pocket:before {
    content: "\e067";
}
.soc-Polar:before {
    content: "\e9c9";
}
.soc-PronounsPage:before {
    content: "\e9ac";
}
.soc-Px:before {
    content: "\e000";
}
.soc-QRcode:before {
    content: "\e98b";
}
.soc-Qobuz:before {
    content: "\e911";
}
.soc-Qq:before {
    content: "\e068";
}
.soc-Quora:before {
    content: "\e069";
}
.soc-RSS:before {
    content: "\e071";
}
.soc-RaidCall:before {
    content: "\e06a";
}
.soc-Ravelry:before {
    content: "\e06b";
}
.soc-Realtor:before {
    content: "\e90f";
}
.soc-RedBubble:before {
    content: "\e979";
}
.soc-Reddit:before {
    content: "\e06c";
}
.soc-Redfin:before {
    content: "\e925";
}
.soc-RefSheet:before {
    content: "\e9bd";
}
.soc-Remote:before {
    content: "\e972";
}
.soc-Renren:before {
    content: "\e06d";
}
.soc-Rentry:before {
    content: "\e993";
}
.soc-ResearchGate:before {
    content: "\e06e";
}
.soc-ResidentAdvisor:before {
    content: "\e06f";
}
.soc-Retrospring:before {
    content: "\e9b2";
}
.soc-ReverbNation:before {
    content: "\e070";
}
.soc-Roblox:before {
    content: "\e9ae";
}
.soc-Seedrs:before {
    content: "\e929";
}
.soc-SeenThis:before {
    content: "\e95d";
}
.soc-Session:before {
    content: "\e9b5";
}
.soc-SharePoint:before {
    content: "\e965";
}
.soc-ShareThis:before {
    content: "\e072";
}
.soc-SheezyArt:before {
    content: "\e98c";
}
.soc-Shopify:before {
    content: "\e927";
}
.soc-Side7:before {
    content: "\e9ca";
}
.soc-Signal:before {
    content: "\e9b6";
}
.soc-SketchFab:before {
    content: "\e90c";
}
.soc-Skype:before {
    content: "\e073";
}
.soc-Slack:before {
    content: "\e918";
}
.soc-SlideShare:before {
    content: "\e074";
}
.soc-SmashCast:before {
    content: "\e03e";
}
.soc-SmashWords:before {
    content: "\e947";
}
.soc-SmugMug:before {
    content: "\e075";
}
.soc-SnapChat:before {
    content: "\e076";
}
.soc-Society6:before {
    content: "\e97a";
}
.soc-SongKick:before {
    content: "\e077";
}
.soc-Soroush:before {
    content: "\e97d";
}
.soc-SoundCloud:before {
    content: "\e078";
}
.soc-Spigot:before {
    content: "\e9ea";
}
.soc-Spip:before {
    content: "\e953";
}
.soc-Splice:before {
    content: "\e96f";
}
.soc-Sporcle:before {
    content: "\e9eb";
}
.soc-Spotify:before {
    content: "\e079";
}
.soc-SpreadShirt:before {
    content: "\e900";
}
.soc-SquareSpace:before {
    content: "\e92d";
}
.soc-SquidgeWorld:before {
    content: "\e9be";
}
.soc-StackExchange:before {
    content: "\e07a";
}
.soc-StackOverflow:before {
    content: "\e07b";
}
.soc-Stage32:before {
    content: "\e96a";
}
.soc-StarCraft:before {
    content: "\e07c";
}
.soc-StayFriends:before {
    content: "\e07d";
}
.soc-Steam:before {
    content: "\e07e";
}
.soc-Stitcher:before {
    content: "\e977";
}
.soc-StoreHouse:before {
    content: "\e07f";
}
.soc-StoryGraph:before {
    content: "\e9ec";
}
.soc-Strava:before {
    content: "\e080";
}
.soc-StrawPage:before {
    content: "\e9a2";
}
.soc-StreamJar:before {
    content: "\e081";
}
.soc-StrudelCafe:before {
    content: "\e991";
}
.soc-StumbleUpon:before {
    content: "\e082";
}
.soc-Subeta:before {
    content: "\e9cb";
}
.soc-Sudomemo:before {
    content: "\e9ed";
}
.soc-Swarm:before {
    content: "\e083";
}
.soc-Tapas:before {
    content: "\e9cc";
}
.soc-TeamSpeak:before {
    content: "\e084";
}
.soc-TeamViewer:before {
    content: "\e085";
}
.soc-Technic:before {
    content: "\e9ee";
}
.soc-Technorati:before {
    content: "\e086";
}
.soc-Telegram:before {
    content: "\e087";
}
.soc-Threads:before {
    content: "\e9b3";
}
.soc-Threema:before {
    content: "\e96e";
}
.soc-Throne:before {
    content: "\e9a7";
}
.soc-Tidal:before {
    content: "\e910";
}
.soc-Tiktok:before {
    content: "\e96b";
}
.soc-Tinder:before {
    content: "\e966";
}
.soc-ToneDen:before {
    content: "\e93a";
}
.soc-Toptal:before {
    content: "\e92e";
}
.soc-Torial:before {
    content: "\e95b";
}
.soc-ToyHouse:before {
    content: "\e986";
}
.soc-Tracks:before {
    content: "\e001";
}
.soc-TraxSource:before {
    content: "\e94d";
}
.soc-Trello:before {
    content: "\e902";
}
.soc-TripAdvisor:before {
    content: "\e088";
}
.soc-Tripit:before {
    content: "\e089";
}
.soc-TripleJ:before {
    content: "\e08a";
}
.soc-Trulia:before {
    content: "\e931";
}
.soc-Tumblr:before {
    content: "\e08b";
}
.soc-TuneIn:before {
    content: "\e903";
}
.soc-Twitch:before {
    content: "\e08c";
}
.soc-Uber:before {
    content: "\e08e";
}
.soc-Ubuntu:before {
    content: "\e958";
}
.soc-Udemy:before {
    content: "\e93f";
}
.soc-UnSplash:before {
    content: "\e914";
}
.soc-UnVale:before {
    content: "\e9bf";
}
.soc-UpWork:before {
    content: "\e934";
}
.soc-VGen:before {
    content: "\e9a4";
}
.soc-VRChat:before {
    content: "\e9c0";
}
.soc-VStream:before {
    content: "\e9a5";
}
.soc-Ventrilo:before {
    content: "\e08f";
}
.soc-Viadeo:before {
    content: "\e090";
}
.soc-Viber:before {
    content: "\e091";
}
.soc-ViewBug:before {
    content: "\e092";
}
.soc-Vimeo:before {
    content: "\e093";
}
.soc-Vkontakte:before {
    content: "\e095";
}
.soc-Vsco:before {
    content: "\e976";
}
.soc-Warcraft:before {
    content: "\e096";
}
.soc-Wattpad:before {
    content: "\e9cd";
}
.soc-WeChat:before {
    content: "\e097";
}
.soc-Weasyl:before {
    content: "\e996";
}
.soc-Webtoon:before {
    content: "\e9ce";
}
.soc-Weibo:before {
    content: "\e098";
}
.soc-WhatsApp:before {
    content: "\e099";
}
.soc-Wickr:before {
    content: "\e952";
}
.soc-Wikipedia:before {
    content: "\e09a";
}
.soc-WindGuru:before {
    content: "\e967";
}
.soc-Windows:before {
    content: "\e09b";
}
.soc-Wix:before {
    content: "\e924";
}
.soc-WordPress:before {
    content: "\e09c";
}
.soc-WorldAnvil:before {
    content: "\e9ef";
}
.soc-Wykop:before {
    content: "\e09d";
}
.soc-X:before {
    content: "\e0aa";
}
.soc-Xbox:before {
    content: "\e09e";
}
.soc-Xing:before {
    content: "\e09f";
}
.soc-YTGaming:before {
    content: "\e90b";
}
.soc-Yahoo:before {
    content: "\e0a0";
}
.soc-Yammer:before {
    content: "\e0a1";
}
.soc-Yandex:before {
    content: "\e0a2";
}
.soc-Yelp:before {
    content: "\e0a3";
}
.soc-YouNow:before {
    content: "\e0a4";
}
.soc-Youtube:before {
    content: "\e0a5";
}
.soc-Zapier:before {
    content: "\e0a6";
}
.soc-Zazzle:before {
    content: "\e97b";
}
.soc-Zerply:before {
    content: "\e0a7";
}
.soc-Zillow:before {
    content: "\e938";
}
.soc-Zomato:before {
    content: "\e0a8";
}
.soc-Zoom:before {
    content: "\e921";
}
.soc-Zynga:before {
    content: "\e0a9";
}
.soc-box:before {
    content: "\e9f0";
}
.soc-macOS:before {
    content: "\e04f";
}
.soc-mixcloud:before {
    content: "\e9a3";
}
.soc-reCAPTCHA:before {
    content: "\e98e";
}
.soc-sourcehut:before {
    content: "\e9f1";
}
