.mat-mdc-menu-panel {
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #CCCCCC !important;
    padding: .75rem .93rem !important;

    .mat-mdc-menu-content {
        padding: 0 !important;
    }

    .mat-mdc-menu-item {
        padding: .5rem 0 !important;
        min-height: 0;

        &:not([disabled]):hover {
            background-color: transparent !important;
            opacity: .8;
        }

        .mat-mdc-menu-item-text {
            color: #666666 !important;

            display: flex;
            align-items: center;
            gap: .68rem;

            img {
                width: 16px;
                height: 16px;
            }
        }

        &.danger .mat-mdc-menu-item-text {
            color: #FF0000 !important;

            img {
                transform: rotate(45deg) !important;
            }
        }
    }
}