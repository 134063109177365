.heading {
    font-size: 32px;
    text-align: center;
    margin: {
        top: 10px;
    };
    padding: {
        top: 70px;
        bottom: 70px
    };
}