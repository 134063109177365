.table-container {
    overflow-x: scroll;
    width: 100% !important;
}

.mat-mdc-table {
    background-color: transparent !important;
    min-width: 800px !important;
    width: 100% !important;

    &.table {
        .mdc-data-table__content {
            background-color: #FFFFFF !important;
        }

        .mat-mdc-header-row {
            .mat-mdc-header-cell {
                border: none;
                color: #333333;
                font-size: .875rem;
                line-height: 20px;
                font-weight: 400;
                text-transform: capitalize;
                background-color: #f7f7f7;
            }

            .sort-th {
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: .25rem;
            }
        }

        .mat-mdc-row {
            height: inherit !important;

            td {
                padding: 1rem;
                color: #333333 !important;
                font-size: .875rem !important;
                line-height: 20px;
                font-weight: 400;

                &:first-child {
                    border-left: 1px solid #CCCCCC !important;
                }

                &:last-child {
                    border-right: 1px solid #CCCCCC !important;
                }
            }


            &:first-child {
                td {
                    border-top: 1px solid #CCCCCC !important;

                    &:first-child {
                        border-radius: 4px 0 0 0;
                    }

                    &:last-child {
                        border-radius: 0 4px 0 0;
                    }
                }
            }


            &:last-child {
                td {
                    border-bottom: 1px solid #CCCCCC !important;

                    &:first-child {
                        border-radius: 0 0 0 4px;
                    }

                    &:last-child {
                        border-radius: 0 0 4px 0;
                    }
                }

            }
        }


    }

    &.table-expandable {
        .mat-mdc-row {

            // Default rows styling
            &:not(.example-detail-row) {
                td {
                    padding: 1rem;
                    color: #333333 !important;
                    font-size: .875rem !important;
                    line-height: 20px;
                    font-weight: 400;
                    // vertical-align: top !important;

                    &:first-child {
                        border-left: 1px solid #CCCCCC !important;
                    }

                    &:last-child {
                        border-right: 1px solid #CCCCCC !important;
                    }
                }
            }

            // Expanded rows styling
            &.example-detail-row {
                td {
                    padding: 0 5.4rem;
                    border-bottom: none !important;
                }
            }

            &.example-expanded-row {
                &:not(:last-child) td {
                    border-bottom: none !important;
                }

                &+tr td {
                    border-right: 1px solid #CCCCCC !important;
                    border-left: 1px solid #CCCCCC !important;
                    border-bottom: 1px solid #CCCCCC !important;
                    padding: 1rem 5.4rem !important;
                }
            }

            &:not(:last-child) {
                td {
                    border-bottom: 1px solid #CCCCCC !important;
                }
            }

            &.example-detail-row td {
                border-bottom: none !important;
            }

            &.example-element-row:first-child {
                td {
                    border-top: 1px solid #CCCCCC !important;

                    &:first-child {
                        border-radius: 4px 0 0 0;
                    }

                    &:last-child {
                        border-radius: 0 4px 0 0;
                    }
                }
            }

            &:nth-last-child(2) {
                td {

                    &:first-child {
                        border-radius: 0 0 0 4px;
                    }

                    &:last-child {
                        border-radius: 0 0 4px 0;
                    }
                }

            }
        }
    }



    .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: .5rem;
    }

    .status {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 4px;

        &.primary {
            background-color: #37A2D9;
        }

        &.success {
            background-color: #34B104;
        }

        &.default {
            background-color: #999999;
        }
    }
}




.mat-mdc-paginator {
    background-color: transparent !important;
    margin-top: .875rem !important;
    padding: 0 !important;
    width: fit-content !important;
    background-color: #FFFFFF !important;

    .mat-mdc-paginator-container {
        padding: 0 !important;
    }

    .mat-mdc-paginator-range-actions {
        border: 1px solid #CCCCCC !important;
        border-radius: 4px !important;

        .mat-mdc-paginator-navigation-previous {
            order: 1 !important;
            border-right: 1px solid #CCCCCC;
            border-radius: 0;
        }

        .mat-mdc-paginator-range-label {
            order: 2 !important;
            margin: 0 32px 0 32px !important;
            color: #333333 !important;
        }

        .mat-mdc-paginator-navigation-next {
            order: 3 !important;
            border-left: 1px solid #CCCCCC;
            border-radius: 0;
        }
    }
}