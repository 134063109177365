.card {
    border-radius: 15px;
    padding: 1.5rem;

    &.card-light {
        // border: 1px solid #CCCCCC;
        background-color: white;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
    }

    &.card-dark {
        // border: 1px solid #DDDDDD;
        background-color: #F4F4F4;
    }

}

.card-form {
    width: calc(100% - 316px);
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .card-label {
        font-size: .875rem;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
    }

    .card {
        padding: 0;

        ul {
            list-style: none;

            // ADDED ITEMS
            .added-items {
                list-style: none;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #CCCCCC;
                padding: .75rem 1.5rem;

                .details,
                .actions {
                    display: flex;
                    align-items: center;
                }

                .details {
                    gap: 1rem;

                    img {
                        width: 50px;
                        height: 50px;
                    }

                    h4 {
                        color: #333333;
                        font-size: 1rem;
                        line-height: 22px;
                        font-weight: 400;
                    }
                }

                .actions {
                    gap: .5rem;
                }
            }

            // ADD ITEM ROW
            .add-row {
                padding: 1.25rem 1.93rem;
                border-top: 1px solid #CCCCCC;

                .plus-btn {
                    text-decoration: underline;
                    color: #184E7F;
                    line-height: 20px;
                    font-size: .875rem;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    img {
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                }
            }
        }
    }
}


// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    .card-form {
        width: 100%;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (max-width: 1400px) {}